<template>
  <v-container fluid v-if="selector" class="pa-0">
    <v-row dense>
      <v-col cols="12">
        <v-select
          :items="lineups"
          v-model="selected"
          label="Lineup"
          item-text="name"
          item-value="id"
          :loading="loading"
          color="color3"
          item-color="color3"
          placeholder="Select A Lineup"
        ></v-select>
      </v-col>
      <v-expand-transition>
        <v-col cols="12" class="text-center" v-if="show && selectedLineup">
          <lineup-details
            :lineup="selectedLineup"
            :division="division"
            :team="team"
            @cancel-click="onCancelClick"
            :tokenStatus="tokenStatus"
            :disabled="true"
            :hideName="true"
          ></lineup-details>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" class="text-center" v-if="selectedLineup">
          <v-btn
            color="color3"
            text x-small
            @click.stop="show = !show"
          >{{show ? 'Hide' : 'show'}} Lineup</v-btn>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-container>
  <v-card color="grey lighten-4" v-else>
    <v-container fluid>
      <v-row dense class="title justify-space-between align-center">
        Line-Up Manager
        <!--
          <ncaa-print></ncaa-print>
        -->
        <v-btn
          color="color3"
          x-small
          text icon
          :loading="loading"
          @click.stop="loadLineups"
        >
          <v-icon>fas fa-sync-alt</v-icon>
        </v-btn>
      </v-row>
      <loading-bar :value="loading"></loading-bar>
      <v-row dense>
        <v-col cols="12">
            <v-window v-model="window" touchless>
              <v-window-item :value="0">
                  <v-card-text class="text-center title">
                    <div>You don't have any lineups yet</div>
                  </v-card-text>
                  <v-card-text class="text-center title" v-if="false">
                    <v-btn color="color3" text @click.stop="newRoster">
                      <v-icon class="mr-3">fas fa-plus</v-icon>
                      Add a lineup
                    </v-btn>
                  </v-card-text>
              </v-window-item>
              <v-window-item :value="1">
                <v-row dense>
                  <v-col cols="12" sm="6" v-for="(lineup, i) in lineups" :key="`${lineup.id}-${i}`">
                    <v-card
                      color="grey lighten-4"
                      class="ma-1"
                      height="100%"
                    >
                      <lineup-details
                        :lineup="lineup"
                        :division="division"
                        :team="team"
                        @cancel-click="onCancelClick"
                        :tokenStatus="tokenStatus"
                        :showButtons="showButtons"
                      ></lineup-details>
                    </v-card>
                  </v-col>
                  <v-col cols="12" v-if="false">
                    <v-card
                      color="grey lighten-2"
                      class="ma-1 d-flex align-center justify-center"
                    >
                    <v-btn color="color3" text @click.stop="newRoster">
                      <v-icon class="mr-3">fas fa-plus</v-icon>
                      Add a lineup
                    </v-btn>
                    </v-card>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import LineupDetails from './LineupDetails'
// import NcaaPrint from './NcaaPrint'
import Lineup from '@/classes/Lineup'

export default {
  props: ['team', 'tournament', 'division', 'selector', 'tokenStatus', 'value', 'showButtons'],
  data () {
    return {
      window: 0,
      lineups: [],
      loading: false,
      selected: this.value,
      show: true
    }
  },
  computed: {
    selectedLineup () {
      return this.lineups && this.selected && this.lineups.find(f => f.id === this.selected)
    }
  },
  methods: {
    newRoster () {
      this.team && this.lineups.push(new Lineup({
        id: 0,
        teamId: this.team.id,
        name: `Line Up ${this.lineups.length + 1}`,
        type: this.division.props.find(f => f.startsWith('lineups-dual-'))
      }))
      this.window = 1
    },
    onCancelClick () {
      this.lineups = this.lineups.filter(f => f.id !== 0)
    },
    loadLineups () {
      this.loading = true
      this.$VBL.team.lineups.get(this.team.id)
        .then(r => {
          this.lineups = r.data.map(m => new Lineup(m))
          this.window = this.lineups.length === 0 ? 0 : 1
        })
        .catch(e => { console.log(e) })
        .finally(() => { this.loading = false })
    },
    handleInput (e) {
      this.$emit('input', this.selected)
    }
  },
  mounted () {
    this.loadLineups()
  },
  watch: {
    'lineups.length': function (val) {
      if (!val) this.window = 0
    },
    selected: 'handleInput'
  },
  components: {
    LineupDetails
    // NcaaPrint
  }
}
</script>
