import DtoUpdate from './DtoUpdate'
import flatten from '@/helpers/ArrayFlatten'

export default class Lineup {
  id = 0
  name = null
  _type = null
  teamId = null
  positions = []
  applyAll = false

  constructor (dto) {
    if (dto) {
      this.update(dto)
    }
  }

  update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['positions']
    DtoUpdate(dto, this, exclude)

    if (dto.positions) {
      this.positions = dto.positions
    }

    // this.ensurePositions()
  }

  get type () {
    return this._type
  }

  set type (val) {
    this._type = (val || 'lineups-dual-1')
    this.initPostions()
  }

  initPostions () {
    if (this.type.startsWith('lineups-dual-')) {
      const pairs = +this.type.replace('lineups-dual-', '')
      this.positions = [...Array(pairs).keys()].map(i => {
        return {
          type: 'dual',
          n: ++i,
          players: []
        }
      })
    }
  }

  ensurePositions () {
    if (this.type.startsWith('lineups-dual-')) {
      const pairs = +this.type.replace('lineups-dual-', '')
      let i = 0
      for (i = 1; i <= pairs; i++) {
        const x = this.positions.find(f => f.type === 'dual' && f.n === i)
        if (!x) {
          console.log('missing position')
          this.positions.push({
            type: 'dual',
            n: i,
            players: []
          })
        }
      }

      for (i = 1; i <= 6; i++) {
        const x = this.positions.find(f => f.type === 'alt' && f.n === i)
        if (!x) {
          this.positions.push({
            type: 'alt',
            n: i,
            players: []
          })
        }
      }

      for (i = 1; i <= 6; i++) {
        const x = this.positions.find(f => f.type === 'removed' && f.n === i)
        if (!x) {
          this.positions.push({
            type: 'removed',
            n: i,
            players: []
          })
        }
      }
    }
  }

  get starters () {
    return this.positions.filter(f => f.type === 'dual')
  }

  get alternates () {
    return this.positions.filter(f => f.type === 'alt')
  }

  get removedPlayers () {
    return this.positions.filter(f => f.type === 'removed')
  }

  get valid () {
    return this.name && this.type && this.starters.filter(f => f.players.length !== 2).length === 0
  }

  get dto () {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      positions: this.positions,
      teamId: this.teamId,
      applyAll: this.applyAll
    }
  }

  get players () {
    return flatten(this.positions.map(m => m.players))
  }

  get playersNeeded () {
    let n = 0
    this.positions.filter(f => ['dual'].includes(f.type)).forEach(p => {
      n = n + (p.type === 'dual' ? 2 : 1)
    })
    return n
  }

  removePosition (p) {
    const a = JSON.stringify(p)
    this.positions = this.positions.filter(f => JSON.stringify(f) !== a)
  }
}
